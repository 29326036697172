<template>
    <div>
        <v-menu offset-y offset-overflow :close-on-content-click="false" v-model="menu">
            <template v-slot:activator="{ on, attrs }">
                <v-icon class="ma-0 text--secondary ml-2" v-bind="attrs" v-on="on" @click="getContacts()">mdi-information</v-icon>
            </template>
            <v-card>
                <v-list v-if="fetching[level]">
                    <v-list-item class="pr-10 opacity-7">
                        <v-list-item-icon>
                            <v-icon>mdi-progress-download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Searching for contacts</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list v-else-if="contacts.length === 0">
                    <v-list-item class="pr-10 opacity-7">
                        <v-list-item-icon>
                            <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>No contacts found</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list v-else-if="$isError(contacts)" subheader>
                    <v-subheader>{{ labels[level] }}</v-subheader>
                    <v-list-item class="pr-10 warning--text opacity-7">
                        <v-list-item-icon>
                            <v-icon color="warning">mdi-alert-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Error during contact retreval</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line subheader v-else>
                    <v-subheader>{{ labels[level] }}</v-subheader>
                    <v-list-item v-for="({ full_name, email }, j) in contacts" :key="`contact-${j}`">
                        <v-list-item-content>
                            <v-list-item-title>{{ full_name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <copy-to-clipboard :textToCopy="email" :iconButton="true"></copy-to-clipboard>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    name: 'ContactsMenu',
    components: { CopyToClipboard },
    props: {
        rpId: Number,
        ids: Object
    },
    data() {
        return {
            fetching: {
                org: false,
                space: false
            },
            contacts: [],
            labels: {
                org: 'Organization managers',
                space: 'Space administrators'
            },
            menu: false
        }
    },
    methods: {
        async getContacts() {
            if (this.menu) return
            this.fetching[this.level] = true
            try {
                const { data } = await this.$axios.get(this.contactListApi)
                this.contacts = data
            } catch (error) {
                this.contacts = error
            } finally {
                this.fetching[this.level] = false
            }
        }
    },
    computed: {
        contactListApi() {
            if (this.level === 'org') return `/resource/${this.rpId}/org/${this.ids.oid}/contacts`
            else return `/resource/${this.rpId}/space/${this.ids.sid}/contacts`
        },
        level() {
            if (this.ids.oid) return 'org'
            return 'space'
        }
    }
}
</script>
