<template>
    <div>
        <div class="pa-4">
            <canvas ref="myChart"></canvas>
        </div>
        <div>{{ options.legendTitle }}</div>
        <ul class="d-flex flex-column pt-2 chartjs-legend text-left" ref="legend-container"></ul>
    </div>
</template>

<script>
import Vue from 'vue'
import ContactsBtn from '@/modules/user/components/ContactsBtn.vue'
import vuetify from '@/plugins/vuetify'
import { isNil } from 'lodash'

import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js'

export default {
    name: 'DoughnutChart',
    props: {
        chartData: Object,
        colors: Array,
        items: Array,
        rpid: Number,
        options: {
            type: Object,
            default() {
                return {
                    legendTitle: '',
                    valueFormatter: null
                }
            }
        }
    },
    data() {
        return {
            doughnutChart: {}
        }
    },
    watch: {
        chartData() {
            this.doughnutChart.data = this.chartData
            this.doughnutChart.update()
        },
        items(items) {
            this.doughnutChart.legend.legendItems.forEach((li, index) => {
                const hidden = items.indexOf(li.text) < 0
                if (hidden !== li.hidden) {
                    li.hidden = hidden
                    this.doughnutChart.toggleDataVisibility(index)
                }
            })
            this.doughnutChart.update()
            this.doughnutChart.render()
        }
    },
    mounted() {
        const self = this
        const htmlLegendPlugin = {
            id: 'htmlLegend',
            afterUpdate(chart, args, options) {
                const ul = self.$refs[options.containerID]

                // Remove old legend items
                while (ul.firstChild) {
                    ul.firstChild.remove()
                }

                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart)

                items.forEach(async (item, i) => {
                    const li = document.createElement('li')
                    li.onclick = () => {
                        const { type } = chart.config
                        if (type === 'pie' || type === 'doughnut') {
                            // Pie and doughnut charts only have a single dataset and visibility is per item
                            chart.toggleDataVisibility(item.index)
                        } else {
                            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
                        }
                        chart.update()
                        self.$emit('legendClick', item)
                    }

                    // Color box
                    const boxSpan = document.createElement('i')
                    boxSpan.style.background = item.fillStyle
                    boxSpan.style.borderColor = item.strokeStyle
                    boxSpan.style.borderWidth = item.lineWidth + 'px'

                    // Text
                    const textContainer = document.createElement('div')
                    textContainer.style.color = item.fontColor
                    textContainer.style.textDecoration = item.hidden ? 'line-through' : ''
                    const text = document.createTextNode(item.text)
                    textContainer.appendChild(text)

                    // Value
                    const valueContainer = document.createElement('div')
                    valueContainer.classList.add('ml-auto')
                    valueContainer.style.color = item.fontColor
                    valueContainer.style.textDecoration = item.hidden ? 'line-through' : ''
                    const valueText = self.options.valueFormatter
                        ? document.createTextNode(self.options.valueFormatter(self.chartData.datasets[0].data[i]))
                        : document.createTextNode(self.chartData.datasets[0].data[i])
                    valueContainer.appendChild(valueText)

                    li.appendChild(boxSpan)
                    li.appendChild(textContainer)

                    const ComponentClass = Vue.extend(ContactsBtn)
                    if (self.chartData.meta && self.chartData.meta.length > 0) {
                        const isMetaPropsAllEmpty = Object.values(self.chartData.meta[i]).every(value => isNil(value))
                        if (!isMetaPropsAllEmpty) {
                            const instance = new ComponentClass({
                                vuetify,
                                propsData: {
                                    rpId: self.rpid,
                                    ids: self.chartData.meta[i]
                                }
                            })
                            instance.$mount()
                            li.appendChild(instance.$el)
                        }
                    }

                    li.appendChild(valueContainer)
                    ul.appendChild(li)
                })
            }
        }

        Chart.register(
            ArcElement,
            LineElement,
            BarElement,
            PointElement,
            BarController,
            BubbleController,
            DoughnutController,
            LineController,
            PieController,
            PolarAreaController,
            RadarController,
            ScatterController,
            CategoryScale,
            LinearScale,
            LogarithmicScale,
            RadialLinearScale,
            TimeScale,
            TimeSeriesScale,
            Decimation,
            Filler,
            Legend,
            Title,
            Tooltip,
            SubTitle
        )
        this.doughnutChart = new Chart(this.$refs.myChart, {
            type: 'doughnut',
            data: this.chartData,
            options: {
                cutout: '70%',
                elements: {
                    arc: {
                        backgroundColor: this.colors,
                        borderColor: 'transparent',
                        // borderRadius: 9999,
                        borderWidth: 0,
                        offset: 0
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(context) {
                                return self.options.valueFormatter ? self.options.valueFormatter(context.raw) : context.formattedValue
                            }
                        }
                    },
                    htmlLegend: {
                        // ID of the container to put the legend in
                        containerID: 'legend-container'
                    },
                    legend: {
                        display: false
                    }
                }
            },
            plugins: [htmlLegendPlugin]
        })
    }
}
</script>

<style lang="scss">
ul.chartjs-legend {
    padding: 0;
    list-style: none;
    li {
        margin: 0;
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 0.1rem 0;
        i {
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
            border-radius: 50%;
            flex-shrink: 0;
        }
    }
}
</style>
